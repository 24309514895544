import "../styles/global.css";
import * as React from "react"
import {
  Navbar,
} from "../components/Navbar";
import { SEO } from "../components/SEOComponent";
import { Link } from "gatsby"
import Footer from "../components/Footer";

// markup
const NotFoundPage = () => {

  return (
    <main>
      <SEO Title="CloudSafari | 404 Page Not Found"
        Description="Error 404 Page Not Found" />

      {/* SearchBar */}

      <div className="tw-pt-12"> </div>
      <div className="tw-rounded-3xl tw-bg-black tw-h-[70vh] tw-w-full tw-relative tw-text-center">
        <img src="https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/404-page-background.jpg?alt=media&token=d9058a83-0ac9-47f1-83a4-18b1006cbf79" className="tw-rounded-3xl tw-h-[70vh] tw-w-full tw-object-cover tw-opacity-60"
        />
        <div className="tw-text-left tw-absolute tw-top-[20%] tw-left-[30px] tw-pr-[30px]">
          <div className="tw-text-2xl md:tw-text-3xl lg:tw-text-6xl tw-text-white">Whoops! Looks like you're lost!</div>
          <br />
          <div className="tw-text-med tw-text-white">Let's get you back on track with one of the following options.</div>
          <br />
          <Link className="tw-text-med tw-text-white tw-underline decoration-solid hover:tw-cursor-pointer" to="/">Home</Link>
          <br />
          <Link className="tw-text-med tw-text-white tw-underline decoration-solid hover:tw-cursor-pointer" to="/discover-tour-operators">Request a Tour Operator</Link>
        </div>
      </div>

      <p>
        {process.env.NODE_ENV === "development" ? (
          <>
            <br />
            This is a dev env.
            <br />
          </>
        ) : null}
      </p>
    </main>
  )
}

export default NotFoundPage